/**
 * Класс для обработки ошибок.
 */
export class WidgetError extends Error {
    errorCode: string;
    errorMessage: string;
    constructor(errorCode: string, errorMessage: string) {
        super(errorMessage)
        this.errorCode = errorCode;
        this.errorMessage = errorMessage;
    }
}
