import {DevParams} from "../../types";
import {CSSStyle} from "./types"

export const parseIntWithoutNaN = (value: string) => {
    const valueInt = value ? parseInt(value, 10) : 0;
    return Number.isNaN(valueInt) ? 0 : valueInt;
}

/**
 * Перебрать элементы объекта.
 *
 * @param object Объект.
 * @param callback Функция обработчик элемента.
 */
export const objectForEach = <T>(object: T, callback: <K extends keyof T>(key: K, value: T[K]) => void) => {
    (Object.keys(object) as (keyof T)[]).forEach((key) => {
        callback(key, object[key])
    })
}

/**
 * Задать стили элементу.
 * 
 * @param element Элемент.
 * @param style Стили.
 */
export const setElementStyle = (element: HTMLElement, style: CSSStyle) => {
    objectForEach(style, (key, value) => {
        element.style[key] = value;
    })
}

/**
 * Получить данные по сайту партнера.
 * 
 * @param dev Данные параметров для разработки.
 */
export const getPartnerSiteData = (dev: DevParams): string => {
    try {
        const {origin, href} = window.location || {};
        const path = href.replace(new RegExp(`^${origin}`), '');
        return btoa(JSON.stringify({
            host: __NODE_ENV__ === 'development' ? dev?.domain ?? origin : origin,
            path
        }));
    } catch (error) {
        return ''
    }
}