import {IWidgetError} from "../Models/SharedModels";

/**
 * Создание элемента.
 *
 * @param tagName Имя тега.
 * @param params Параметры.
 */
export const createElement = <K extends keyof HTMLElementTagNameMap>(
    tagName: K,
    params: Partial<HTMLElementTagNameMap[K]>
): HTMLElementTagNameMap[K] => {
    return Object.assign(document.createElement(tagName), params);
}

/**
 * Проверка видимости элемента.
 * @param id Идентификатор элемента.
 */
export const checkElementVisibility = (id: string): boolean => {
    const element = document.getElementById(id);
    if (element.getBoundingClientRect().width === 0 || element.getBoundingClientRect().height === 0) {
        return false;
    }
    if (window.getComputedStyle(element).visibility === 'hidden') {
        return false;
    }
    if (window.getComputedStyle(element).opacity === '0') {
        return false;
    }
    return true;
};

export const getErrorWithMeta = ({errorCode, errorMessage}: IWidgetError, meta?: string): IWidgetError => {
    return {errorCode, errorMessage: `${errorMessage} ${meta && meta.toString ? meta.toString() : ''}`};
}

export const genUrl = (origin: string, params?: Partial<Omit<URL, 'searchParams'>> & {searchParams: Record<string, string>}): string => {
    const {searchParams, ...other} = params || {};
    const url = Object.assign(new URL(origin), other);

    searchParams && Object.keys(searchParams).forEach((key) => {
        if (searchParams[key]) {
            url.searchParams.set(key, searchParams[key]);
        }
    })

    return url.toString()
};