import {IWidgetError} from "../Models/SharedModels";
import {EPostMessageSystemName} from "./MessageTypes";

declare global {
    interface Window {
        SberWidgetsSDK: ISberWidgets;
    }
}

/**
 * Хранилище данных.
 *
 * @property {boolean} debugMode Режим отладки.
 */
export interface StoreType {
    debugMode: boolean;
}

/**
 * Параметры для разработки.
 *
 * @property {string} domain Домен партнер.
 */
export interface DevParams {
    domain: string;
}

/**
 * @property {string} id Идентификатор виджета.
 * @property {string} key АПИ идентификатор.
 * @property {string} erid Идентификатор рекламы.
 * @property {string} partnerId Идентификатор партнера.
 * @property {HTMLElement} container Блок в котором создается iframe.
 * @property {(message: string) => void} [onSuccess] Колбэк успешного создания.
 * @property {(error: string | IWidgetError | Event) => void} [onError] Колбэк ошибки.
 * @property {(message: string) => void} [onMessage] Колбэк получения postMessage.
 * @property {IOrderData} [orderData] Данные по платежке.
 * @property {(data: unknown) => void} [onSendOrderDataSuccess] Колбэк, в случае успеха отправки данных по птатежке.
 * @property {(data: unknown) => void} [onSendOrderDataError] Коллбэк, в случае ошибки отправки данных по платежке.
 * @property {(data: unknown) => void} [onReceivedOrderDataStatuses] Коллбэк, получение партнером статусов платежек.
 * @property {boolean} [debugMode] Режим отладки для виджета(Выводится информация в консоль).
 * @property {boolean} [disableCertVerify] Отключение проверки сертификата.
 * @property {DevParams} [dev] Параметры для разработки.
 */
export interface IWidgetParams {
    widgetId: string;
    key: string;
    erid?: string;
    partnerId: string;
    container: HTMLElement;
    onSuccess?: (message: string) => void;
    onError?: (error: string | IWidgetError | Event) => void;
    onMessage?: (message: IWidgetPostMessage | string) => void;
    orderData?: IOrderData;
    onSendOrderDataSuccess?: (data: unknown) => void;
    onSendOrderDataError?: (data: unknown) => void;
    onReceivedOrderDataStatuses?: (data: unknown) => Promise<void>;
    debugMode?: boolean;
    disableCertVerify?: boolean;
    dev?: DevParams;
}


export interface ISberWidgets {
    create: (params: IWidgetParams) => void;
    destroy: (id: string) => void;
    sendMessage: (id: string, systemName: string, description: string, payload: string) => void;
    debugModeOn: () => void;
    debugModeOff: () => void;
}


/**
 * Интерфейс сообщений.
 * @property {EPostMessageSystemName} systemName Системное имя.
 * @property {string} [rquid] Уникальный идентификатор.
 * @property {string} [description] Текстовое описание.
 * @property {string | IWidgetPayload | IWidgetError | IOrderData} [payload] Дополнительные данные.
 */
export interface IWidgetPostMessage {
    systemName: EPostMessageSystemName;
    rquid?: string;
    description?: string;
    payload?: string | IWidgetPayload | IOrderData | IWidgetError;
}

/**
 * Интерфейс payload.
 * @property {string} url URL.
 */
export interface IWidgetPayload {
    url: string;
}


/**
 * Значения style.display
 */
export enum EStyleDisplay {
    NONE = 'none',
    BLOCK = 'block',
}

/**
 * Типы ошибок.
 * LOG - Лог.
 * INFO - Информация.
 * WARM - Предупреждение.
 * ERROR - Ошибка.
 */
export enum ELogType {
    LOG = 'log',
    INFO = 'info',
    WARN = 'warn',
    ERROR = 'error',
}

/**
 * Наименование стилей.
 * Соответствуют свойствам CSS (Дублировать документацию не вижу смысла).
 */
export interface IIframeStyles {
    position: string;
    top: string;
    left: string;
    margin: string;
    padding: string;
    height: string;
    width: string;
    border: string;
    borderWidth: string;
    overflowY: string;
    overflowX: string;
    zIndex: string;
}

/**
 * Способ расчета НДС.
 * INCLUDED - НДС рассчитан по указанной ставке и включен в указанную сумму платежа.
 * MANUAL - НДС рассчитан и введен вручную (для сложных процентных ставок).
 * NO_VAT - НДС не облагается.
 * ONTOP - НДС рассчитан по указанной ставке и добавляется к сумме платежа.
 */
export enum IVatCalcMethod {
    INCLUDED = 'INCLUDED',
    MANUAL = 'MANUAL',
    NO_VAT = 'NO_VAT',
    ONTOP = 'ONTOP',
}

/**
 * Данные по счету получателя.
 * @property accountNumber Р/с получателя.
 * @property inn ИНН получателя.
 * @property kpp КПП получателя.
 * @property corrAccount Корр.счёт банка получателя.
 * @property bic БИК банка получателя.
 * @property nameBank Имя банка получателя.
 */
export interface IAccountData {
    accountNumber: string;
    inn: string;
    kpp: string;
    corrAccount: string;
    bic: string;
    nameBank: string;
}

/**
 * Данные по НДС.
 * @property amount Сумма НДС.
 * @property calcMethod Способ расчета НДС.
 * @property rate Ставка НДС.
 */
export interface IVatData {
    amount?: string;
    calcMethod: IVatCalcMethod;
    rate?: number;
}

/**
 * Интерфейс передачи данных для КВК.
 * @property [extDataId] ID данных по КВК.
 * @property [orderNumber] Номер заказа.
 * @property amount Сумма платежа.
 * @property [isPartPayment] Возможность частичной оплаты.
 * @property purpose Назначение платежа.
 * @property name Наименование получателя.
 * @property accountData Данные по счету получателя.
 */
export interface IOrderData {
    extDataId?: string;
    orderNumber?: string;
    amount: string;
    isPartPayment?: boolean;
    purpose: string;
    name: string;
    accountData: IAccountData;
    vatData: IVatData;
}
