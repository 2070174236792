import {CHECK_CERTIFICATE_URL} from "../const";

/**
 * Время ожидания проверки сертификата.
 */
const TIMEOUT_WAIT_CERT_VERIFY = 10000;

/**
 * Проверка установлен ли сертификат у клиента.
 */
export const certificateVerify = (): Promise<boolean> => {
    return new Promise((resolve) => {
        let timer: ReturnType<typeof setTimeout> = null;
        const xhr = Object.assign(new XMLHttpRequest, {
            onload: () => {
                timer && clearTimeout(timer);
                resolve(true);
            },
            onabort: () => resolve(false),
            onerror: () => {
                timer && clearTimeout(timer);
                resolve(false);
            }
        });
        xhr.open('GET', CHECK_CERTIFICATE_URL);
        xhr.send();
        timer = setTimeout(() => xhr.abort(), TIMEOUT_WAIT_CERT_VERIFY);
    });
}

