import {EStyleDisplay} from '../types';
import {CSSStyle} from './WidgetFrame/types';

export const FRAME_INITIAL_STYLES: CSSStyle = {
    display: EStyleDisplay.NONE,
    width: '100px',
    height: '1px',
    borderWidth: '0',
    transform: 'none !important',
}

/**
 * Стили, которые необходимо изменить для перехода в полноэкранное отображение.
 */
export const STYLES_FULL_SCREEN_MODE: CSSStyle = {
    position: "fixed",
    top: "0",
    left: "0",
    margin: "0",
    padding: "0",
    height: "100%",
    width: "100%",
    border: "none",
    borderWidth: "0",
    overflowY: "auto",
    overflowX: "hidden",
    zIndex: "9999999999999",
    filter: 'none',
    borderRadius: '0',
    transform: 'none !important',
};
