import {genUrl} from "../utils";
import {BaseWidgetFrame} from "./BaseWidgetFrame";
import {getPartnerSiteData} from "./utils";

export class WidgetFrameSnapshot extends BaseWidgetFrame {
    name = 'snapshot';

    /**
     * Создание iframe.
     *
     * @param widgetId Идентификатор виджета.
     * @param key Ключ.
     * @param partnerId Идентификатор партнера.
     */
    getFrameSrc = (widgetId: string, key: string, partnerId: string): string => {
        const {erid, dev} = this.params;

        return genUrl(this.getOrigin(), {
            pathname: `${this.getRootPath()}snapshot.html`,
            searchParams: {key, widgetId, partnerId, partnerSite: getPartnerSiteData(dev), erid},
            hash: "/"
        });
    };
}
