/**
 * Origin SDK (без завершающего слэша).
 */
export const SDK_DOMAIN_URL = process.env.SDK_DOMAIN_URL;

/**
 * Адрес текущего приложения.
 */
export const APP_BASE_PATH = "/ic/ufs/sbbol-e-com/";

/**
 * Origin для виджета и домен для построения url виджета(без завершающего слэша).
 */
export const IFRAME_DOMAIN_URL = process.env.IFRAME_DOMAIN_URL; //стендозависимая ссылка подставлется в джобе деплоя

/**
 * Адрес страницы по которой проверяем наличие сертификата.
 */
export const CHECK_CERTIFICATE_URL = "https://widgetecom.sberbank.ru/widgets-sdk.js"

/**
 * allow-same-origin - для проверки origin в postMessage.
 */
export const IFRAME_SAND_BOX =
    "allow-modals allow-popups allow-storage-access-by-user-activation allow-popups-to-escape-sandbox allow-scripts allow-top-navigation-by-user-activation allow-same-origin allow-forms";

/**
 * Время ожидания готовности вмджета. Как вариант(более лучший) партнер должен сам определять при создании виджета.
 */
export const WIDGET_READY_TIMEOUT = 10000;
