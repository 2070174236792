import {IWidgetError} from "../Models/SharedModels"

export const WIDGET_CREATE_IMPOSSIBLE: IWidgetError = {
    errorCode: 'widget_create_impossible',
    errorMessage: 'Невозможно создать виджет.',
}

export const ERROR_IFRAME_IS_NOT_VISIBLE: IWidgetError = {
    errorCode: 'iframe_is_not_visible',
    errorMessage: 'Iframe не виден.',
}

export const ERROR_WIDGET_LOADING: IWidgetError = {
    errorCode: 'error_widget_loading',
    errorMessage: 'Ошибка загрузки виджета.',
}
