import {IWidgetError} from "../../Models/SharedModels";

export const WIDGET_FRAME_NOT_FOUND_ERROR: IWidgetError = {
    errorCode: 'widget_frame_not_found_error',
    errorMessage: 'Ошибка создания iframe'
};

export const WIDGET_FRAME_CREATE_ERROR: IWidgetError = {
    errorCode: 'widget_frame_create_error',
    errorMessage: 'Ошибка создания iframe'
};

export const WIDGET_FRAME_LOADING_ERROR: IWidgetError = {
    errorCode: 'widget_frame_loading_error',
    errorMessage: 'Ошибка загрузки iframe'
};

export const WIDGET_FRAME_FULL_SCREEN_ON_ERROR: IWidgetError = {
    errorCode: 'full_screen_mode_on_error',
    errorMessage: 'Ошибка перехода в полноэкранный режим.',
}

export const WIDGET_FRAME_FULL_SCREEN_OFF_ERROR: IWidgetError = {
    errorCode: 'full_screen_mode_off_error',
    errorMessage: 'Ошибка выхода из полноэкранного режима.',
}