import {IWidgetParams} from '../types';
import {Store} from './store';
import {EPostMessageSystemName} from '../types/MessageTypes';
import {Widget} from './Widget/Widget';
import {IWidget} from './Widget/types';

const Widgets: Record<string, IWidget> = {};

/**
 * Метод создания виджета.
 *
 * @param params Параметры виджета.
 */
export const create = (params: IWidgetParams) => {
    Widgets[params.widgetId] = new Widget(params);
};

/**
 * Отправка сообщения в виджет.
 *
 * @param widgetId Идентификатор виджета.
 * @param systemName Системное имя сообщения.
 * @param [payload] Полезная нагрузка.
 */
export const sendMessage = (widgetId: string, systemName: EPostMessageSystemName, payload?: unknown) => {
    Widgets[widgetId]?.sendMessage(systemName, payload);
};

/**
 * Удаление виджета.
 *
 * @param widgetId Идентификатор виджета.
 */
export const destroy = (widgetId: string) => {
    Widgets[widgetId]?.destroy();
    delete Widgets[widgetId];
};

/**
 * Включение глобального режима отладки.
 */
export const debugModeOn = (): void => {
    Store.debugMode = true;
};

/**
 * Выключение глобального режима отладки.
 */
export const debugModeOff = (): void => {
    Store.debugMode = false;
};

