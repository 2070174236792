import {IMessageData, SendMessageArgs, MessageHandler, MessagePayload, MessageMeta, ProcessHandler} from './types';

/**
 * Сервис для работы с сообщениями.
 */
export class EventMessageService<Event extends string, EventTypes> {
    private origin: string;

    private target: Window | null;

    private handlers: {[E in Event]?: MessageHandler<E, EventTypes>} = {};

    private handlerBefore?: ProcessHandler<Event, EventTypes>;

    /**
     * @param target Целевое окно (объект Window).
     * @param origin Источник.
     */
    constructor(target: Window | null, origin: string) {
        this.target = target;
        this.origin = origin;

        window.addEventListener('message', this.listener);
    }

    /**
     * Слушатель события.
     *
     * @param event Событие.
     */
    private listener = <E extends Event>({origin, data}: MessageEvent<string>) => {
        if (origin === this.origin && typeof data === 'string') {
            try {
                const {systemName, payload, meta} = JSON.parse(data) as 
                    IMessageData<E, MessagePayload<E, EventTypes>, MessageMeta<E, EventTypes>> || {};
                const handler = this.handlers[systemName]

                if (handler && this.handlerBefore?.(systemName, payload, meta, data) !== false) {
                    handler(payload, meta);
                }
            } catch (error) {
                // TODO Подумать над обработкой ошибок.
                console.error(error);
            }
        }
    };

    /**
     * Зарегистрировать обработчик который вызывается на любое событие и выполняется до основного.
     * Если обработчик вернет false, основной метод не будет выполнен.
     *
     * @param handlerBefore Обработчик события.
     */
    registerBefore = (handlerBefore: ProcessHandler<Event, EventTypes>) => {
        this.handlerBefore = handlerBefore;
    };

    /**
     * Зарегистрировать обработчик события.
     *
     * @param systemName Системное имя события.
     * @param handler Обработчик события.
     */
    register = <E extends Event>(systemName: E, handler: MessageHandler<E, EventTypes>) => {
        this.handlers[systemName] = handler;
    };

    /**
     * Зарегистрировать разовый обработчик события.
     *
     * @param systemName Системное имя события.
     * @param handler Обработчик события.
     */
    registerOnce = <E extends Event>(systemName: E, handler: MessageHandler<E, EventTypes>) => {
        this.register(systemName, (payload, meta) => {
            handler(payload, meta);
            this.remove(systemName);
        });
    };

    /**
     * Удалить слушатель события.
     * 
     * @param systemName Системное имя события.
     */
    remove = <E extends Event>(...systemName: E[]) => {
        systemName?.forEach((systemName) => {
            delete this.handlers[systemName];
        });
    }

    /**
     * Отправка события.
     *
     * @param systemName Системное имя события.
     * @param [payload] Полезная нагрузка.
     */
    send = <E extends Event>(systemName: E, ...args: SendMessageArgs<MessagePayload<E, EventTypes>, MessageMeta<E, EventTypes>>): void => {
        if (this.origin && this.target) {
            try {
                const data: IMessageData<E, MessagePayload<E, EventTypes>, MessageMeta<E, EventTypes>> = {
                    systemName,
                    payload: args?.[0] as MessagePayload<E, EventTypes>,
                    meta: args?.[1] as MessageMeta<E, EventTypes>,
                };
                this.target.postMessage(JSON.stringify(data), this.origin);
            } catch (error) {
                // TODO Подумать над обработкой ошибок.
                console.error(error);
            }
        }
    };

    /**
     * Удалить слушатель событий.
     */
    destroy = () => {
        window.removeEventListener('message', this.listener);
    };
}
